<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/csm_Ersa-ECOSELECT4-01-900x675_c2dae979aa.png"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
              <br><br><br>
              <div class="row">
                <div class="col-2"></div>
                <div class="mt-1 col-6">
                  <h1 class="font-size-48 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>
                Inline and batch selective soldering system for the highest quality
                  </h2>
                </div>
                </div>
              </div>
              
            </div>
            <!-- end row -->
            <br /><br /><br />
            <div class="row">
              <div class="col-xl-6">
                <br /><br />
                <div class="mt-4">
                  <h1>Tech-Highlights ECOSELECT 4</h1>
                  <br />
                  <ul class="container">
                    <li>
                      <h4>
                       Ready for the Future: Upgradeable flexible inline soldering system with VERSAFLOW 4 technology
                      </h4>
                    </li>
                    <br />
                    <li>
                      <h4>Intuitive operating concept</h4>
                    </li>
                      <br />
                    <li>
                      <h4>Highest process reliability due to proven control systems</h4>
                    </li>
                   
                    <br />
                  </ul>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/21csm_Ersa-Selective-SMARTFLOW-006-900x600_ae6f26d723.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br />

            <div class="row">
              <div class="col-8" style="margin-left: 50px">
                <h1><strong>Technical data</strong></h1>
              </div>
              <div class="col-3"></div>
            </div>
            <br />
            <b-tabs pills card vertical style="font-size: 18px">
              <b-tab active title="Dimensions">
                <div class="row" style="margin-bottom: 5px; margin-left: 10%">
                  <div class="col">
                    <h4>Length:  2.000 mm</h4>
                  </div>
                  <div class="col">
                    <h4>Height: 1.700 mm</h4>
                  </div>
                </div>
                <div class="row" style="margin-left: 10%">
                  <div class="col"><h4>Width: 1.800 mm</h4></div>
                  <div class="col"></div>
                </div>
              </b-tab>

              <b-tab title="Conveyor support">
                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4>Roller conveyor</h4>
                  </div>
                  <div class="col">
                    <h4>Maximum PCB build-up at the top up to 120 mm</h4>
                  </div>
                </div>
                <div class="row" style="margin-left: 10%">
                  <div class="col"><h4>PCB width: 50-508 mm</h4></div>
                  <div class="col">
                    <h4>Maximum PCB build-up at the bottom up to 60 mm</h4>
                  </div>
                </div>
                <div class="row" style="margin-left: 10%">
                  <div class="col"><h4>PCB length: 127-508 mm</h4></div>
                  <div class="col">
                    <h4>Maximum PCB weight: 15 kg</h4>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Flux / Preheat / Solder Module">
                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4>
                      <strong>Fluxer:</strong> Drop-Jet in different sizes
                    </h4>
                  </div>
                  <div class="col">
                    <h4><strong> Soldering module:</strong></h4>
                  </div>
                </div>

                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4>
                      <strong> Preheat module:</strong>IR heating bottom, convection (option), powerconvection (option)
                    </h4>
                  </div>
                  <div class="col"><h4>Electromagnetic solder pot</h4></div>
                </div>

                   <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4></h4>
                  </div>
                  <div class="col"><h4>Mini-wave 13 kg, up to 2 pots</h4></div>
                </div>
                
               
              </b-tab>
            </b-tabs>

            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

            <div class="row">
              <div class="col-xl-12">
                <div class="">
                  <div class="">
                    <div class="row">
                      <div class="col-xl-7">
                        <!-- 16:9 aspect ratio -->
                        <div class="ratio ratio-21x9">
                          <iframe
                            style="width: 500px; height: 300px"
                            title="YouToube Video" 
                            src="https://www.youtube.com/embed/d8PdDyU4Hpw"  
                            allowfullscreen=""
                          ></iframe>
                        </div>
                      </div>

                      <div class="col-xl-5">
                        <!-- 16:9 aspect ratio -->
                        <br /><br />
                        <h1>Ersa Selective Soldering : ECOSELECT 4</h1>
                        <p style="font-size: 16px;color:black ">
                         Batch and inline selective soldering system with world-leading technology.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>

            <br /><br />
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>